<template>
    <v-dialog v-model="isStayAddDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Stay Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeStayAddDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="addStayForm">
                    <v-container>
                        <div class="start-date">
                            <v-text-field v-model="newStay.startDate" name="start-date" type="date" id="start-date"
                                ref="addStayStartDate" class="form-control" label="Start Date"
                                :rules="stayValidation.startDate"></v-text-field>
                        </div>
                        <div class="end-date">
                            <v-text-field v-model="newStay.endDate" name="end-date" type="date" id="end-date"
                                class="form-control" label="End Date" :rules="stayValidation.endDate"></v-text-field>
                        </div>
                        <div class="location">
                            <v-autocomplete :items="newStay.locationSuggestions" item-title="main_text"
                                item-value="uuid" v-model="newStay.location" label="Location"
                                :rules="stayValidation.locationRules" style="margin: 15px 0px" @keyup="
        debouncedFetchLocationSuggestions($event.target.value, 'stay', false)
        " :loading="locationLoading.stay ? true : false"></v-autocomplete>
                        </div>
                        <v-row>
                            <v-col>
                                <v-btn color="primary" class="w-100" @click="addStayDetail">Add Stay Detail</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="editStayDetailModal.show" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Stay Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeStayEditDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form ref="editStayForm">
                    <v-container>
                        <div class="start-date">
                            <v-text-field v-model="newStay.startDate" name="start-date" type="date" id="start-date"
                                ref="editStayStartDate" class="form-control" label="Start Date"
                                :rules="stayValidation.startDate"></v-text-field>
                        </div>
                        <div class="end-date">
                            <v-text-field v-model="newStay.endDate" name="end-date" type="date" id="end-date"
                                class="form-control" label="End Date" :rules="stayValidation.endDate"></v-text-field>
                        </div>
                        <div class="location">
                            <v-autocomplete :items="newStay.locationSuggestions" item-title="main_text"
                                item-value="uuid" v-model="newStay.location" label="Location"
                                :rules="stayValidation.locationRules" style="margin: 15px 0px" @keyup="
        debouncedFetchLocationSuggestions($event.target.value, 'stay', false)
        " :loading="locationLoading.stay ? true : false"></v-autocomplete>
                        </div>
                        <v-row>
                            <v-col cols="6">
                                <v-btn color="error" variant="outlined" class="w-100"
                                    @click="deleteStayDetail">Delete</v-btn>
                            </v-col>
                            <v-col cols="6">

                                <v-btn color="primary" class="w-100" @click="editStayDetail">Save
                                    Changes</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="isPaxEditDialogOpen" persistent max-width="400px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Edit Guest Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closePaxEditDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="paxEditForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_adults" label="Adults" type="number"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_childs" label="Children" type="number"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="editedPax.no_of_infants" label="Infants" type="number"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100">Edit Guest Details</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="addItineraryShow" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Add Itinerary</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="addItineraryShow = !addItineraryShow">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="addItineraryForm">
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="types" item-title="type" item-value="slug" label="Type"
                                    v-model="newItinerary.type"
                                    @update:modelValue="(value) => onCategoryChange(newItinerary.category, false)"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="categories" item-title="type" item-value="slug" label="Category"
                                    v-model="newItinerary.category"
                                    @update:modelValue="(value) => onCategoryChange(value, false)"></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field type="date" label="Date" v-model="newItinerary.date"
                                    readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field type="time" label="Time" v-model="newItinerary.pickUpTime"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="newItinerary.pickUpLocationSuggestions" item-title="main_text"
                                    item-value="uuid" label="Pick Up Location"
                                    :rules="itineraryLocationValidation.pickUpLocationRules"
                                    v-model="newItinerary.pickUpLocation" @keyup="
        debouncedFetchLocationSuggestions(
            $event.target.value,
            'pick_up',
            false
        )
        " :loading="locationLoading.pick_up ? true : false"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-autocomplete :items="newItinerary.destinationLocationSuggestions"
                                    item-title="main_text" item-value="uuid" label="Drop Off Location"
                                    :rules="itineraryLocationValidation.destinationLocationRules"
                                    v-model="newItinerary.destinationLocation" @keyup="
        debouncedFetchLocationSuggestions(
            $event.target.value,
            'destination',
            false
        )
        " :loading="locationLoading.destination ? true : false"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-file-input v-model="newItinerary.tickets" label="Select ticket(s)" multiple clearable
                                    chips accept=".jpg,.jpeg,.png,.pdf"
                                    @change="(event) => addTickets(event, false)"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row> </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea label="Remarks" v-model="newItinerary.remarks" rows="1"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field type="time" label="Return Time (Optional)"
                                    v-model="newItinerary.returnTime" clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-card-actions>
                    <v-container>
                        <v-btn class="button-1 w-100" @click="addTransferDetail">Add Transfer</v-btn>
                    </v-container>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="isAssignDetailDialogOpen" persistent max-width="600px">
        <v-card>
            <v-card-text>
                <v-card-title class="d-flex justify-space-between align-center">
                    <span class="headline">Assign Details</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeAssignDetailDialog">
                        <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-form ref="form">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p>Available {{ this.currentRole === 'tour-guide' ? 'tour guide' : this.currentRole }}:
                                    {{ dntData.length }}</p>
                                <p>Selected Transfers: {{ assignIds.length }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete :items="dntData" item-title="full_name" item-value="uuid"
                                    label="Select Driver/Tour Guide" v-model="dntId"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-btn class="button-1 w-100" :disabled="dntId == '' || assignIds.length == 0"
                                    @click="submitRequest" :loading="isLoading">Request</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>

    <section id="content" class="home">
        <div class="main-content">
            <TopNav />

            <div class="title">
                <h1>Job Detail</h1>
                <p>Dashboard > Calendar > Job</p>
                <div class="job-infomation">
                    <div class="button-1" v-if="data && data.data && data.data.stays && data.data.stays.length == 0">
                        Priority</div>
                    <div class="button-1" v-else>
                        <p>Standard</p>
                    </div>
                    <v-btn href="" color="error" prepend-icon="mdi-delete" :loading="isLoading">Delete Job</v-btn>
                </div>
            </div>

            <div class="user-details container">
                <div v-if="data && data.data && data.data.tourist">
                    <div class="user">
                        <div class="image-container">
                            <Image :imagePath="data.data.tourist.image_path"></Image>
                        </div>
                        <div class="details">
                            <h1 class="name">{{ data.data.tourist.first_name + ' ' + data.data.tourist.last_name }}</h1>
                            <p>+{{ data.data.tourist.contact_numbers.find((a) => a.primary ===
        1).country_code.country_code +
        data.data.tourist.contact_numbers.find((a) => a.primary === 1).contact_number
                                }}
                            </p>
                        </div>
                    </div>
                    <div class="contacts">
                        <a href="" @click.prevent="$router.push('/chats/' + data.data.tourist.uuid)" class="chat">
                            <img src="@/assets/icons/chat.svg" alt="chat.svg">
                        </a>
                        <a href="" @click.prevent="sendWhatsapp(data.data.tourist.contact_numbers.find((a) => a.primary ===
        1).country_code.country_code +
        data.data.tourist.contact_numbers.find((a) => a.primary === 1).contact_number)" class="whatsapp">
                            <img src="@/assets/icons/whatsapp.svg" alt="whatsapp.svg">
                        </a>
                    </div>
                </div>
                <div v-else>
                    <p>Loading...</p>
                </div>
            </div>

            <div class="stay-pax-details">
                <div class="stay-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.873" height="22.676"
                            viewBox="0 0 15.873 22.676">
                            <path id="Icon_material-location-on" data-name="Icon material-location-on"
                                d="M15.437,3A7.931,7.931,0,0,0,7.5,10.937c0,5.953,7.937,14.74,7.937,14.74s7.937-8.787,7.937-14.74A7.931,7.931,0,0,0,15.437,3Zm0,10.771a2.835,2.835,0,1,1,2.835-2.835A2.836,2.836,0,0,1,15.437,13.771Z"
                                transform="translate(-7.5 -3)" fill="currentColor" />
                        </svg>

                        <p>Stay</p>
                    </div>

                    <div class="list">
                        <div class="list-item" v-for="(stay, index) in data.data.stays" :key="index" v-if="data"
                            @click="openStayEditDialog(index)">
                            <div class="list-item-title">{{ stay.hotel.main_text }}</div>
                            <div class="list-item-subtitle">
                                {{ formatDateFull(stay.start_date) }} - {{ formatDateFull(stay.end_date) }}
                            </div>
                        </div>

                        <p v-if="data && data.data && data.data.stays && data.data.stays.length == 0">No Stay Details
                            Available</p>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-plus" @click="openStayAddDialog" color="primary"
                            variant="text"></v-btn>
                    </div>
                </div>

                <div class="pax-details container">
                    <div class="icon-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28.768" height="18.493"
                            viewBox="0 0 28.768 18.493">
                            <path id="Icon_ionic-md-people" data-name="Icon ionic-md-people"
                                d="M21.864,15.58a3.853,3.853,0,1,0-3.923-3.853A3.9,3.9,0,0,0,21.864,15.58Zm-10.461,0A3.853,3.853,0,1,0,7.48,11.728,3.9,3.9,0,0,0,11.4,15.58Zm0,2.825c-3.073,0-9.153,1.477-9.153,4.495v3.467H20.743V22.9C20.743,19.883,14.476,18.406,11.4,18.406Zm10.461.707a6.979,6.979,0,0,0-1.121.064c1.5,1.092,2.055,1.8,2.055,3.724v3.467h8.219V22.9C31.017,19.883,24.937,19.112,21.864,19.112Z"
                                transform="translate(-2.25 -7.875)" fill="currentColor" />
                        </svg>

                        <p>Guest</p>
                    </div>

                    <div class="content"
                        v-if="data && data.data && ('no_of_adults' in data.data) && ('no_of_childs' in data.data) && ('no_of_infants' in data.data)">
                        <p>Adults: {{ data.data.no_of_adults }}</p>
                        <p>Childs: {{ data.data.no_of_childs }}</p>
                        <p>Infants: {{ data.data.no_of_infants }}</p>
                    </div>

                    <div class="icon-wrapper">
                        <v-btn density="compact" icon="mdi-pencil" @click="openPaxEditDialog" color="primary"
                            variant="text"></v-btn>
                    </div>
                </div>
            </div>

            <div class="priority-details" v-if="data && data.data && data.data.priority">
                <div class="container">
                    <div class="priority-details">
                        <p class="mt-0"><strong>Priority Details</strong></p>
                        <p class="mt-0"><v-icon class="mr-4">mdi-calendar</v-icon> Type: <strong>{{
        data.data.priority.type
    }}</strong></p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-signal</v-icon> Ping: <strong>{{
            data.data.priority.ping.ping_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-cash</v-icon> Payment Type: <strong>{{
        data.data.priority.payment_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-car</v-icon> Vehicle Type: <strong>{{
        data.data.priority.vehicle_type.type_name }}</strong>
                        </p>
                        <p class="mt-1"><v-icon class="mr-4">mdi-currency-usd</v-icon> Job Cost: <strong>${{
        data.data.priority.job_cost
    }}</strong></p>
                    </div>
                </div>
                <div class="container timer">
                    <v-icon class="mb-2">mdi-clock-outline</v-icon>
                    <p>Searching for drivers...</p>
                </div>
            </div>


            <div class="transfer-container" v-if="groupedTransfers && groupedTransfers.length > 0"
                v-for="(group, index) in groupedTransfers" :key="index">
                <div>
                    <div class="transfer-header">
                        <h3>{{ group.date }}</h3>
                        <v-btn variant="text" prepend-icon="mdi-plus" @click="addItinerary(new Date(group.date))">Add
                            Transfer</v-btn>
                    </div>

                    <v-data-table :headers="headers" :items="group.transfers" :items-per-page="-1"
                        :sort-by="[{ key: 'pick_up_time', order: 'asc' }]" disable-pagination hide-default-footer
                        item-key="uuid">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ this.$moment(item.pick_up_time).format('HH:mm') }}</td>
                                <td>{{ item.transfer_type_name }}</td>
                                <td>{{ item.transfer_type.transfer_type_special_slug }}</td>
                                <td>{{ item.from.main_text }}</td>
                                <td>{{ item.to.main_text }}</td>
                                <td>{{ item.remarks ?? '-' }}</td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null" role="driver"
                                        :is-selected="assignIds.includes(item.uuid)" :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        :attendances="item.attendances ?? []"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null"
                                        role="contractor" :is-selected="assignIds.includes(item.uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                                <td>
                                    <DriverAssignmentCard :is-priority-job="data.data.priority !== null"
                                        role="tour-guide" :is-selected="assignIds.includes(item.uuid)"
                                        :current-role="currentRole"
                                        :assigned-job-request-details="item.assigned_job_request_details"
                                        @assign="handleCheckboxClick(item.uuid, $event)" />
                                </td>
                                <td>
                                    <v-btn density="compact" icon="mdi-pencil" @click="openStayEditDialog"
                                        color="primary" variant="text"></v-btn>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>
            </div>

            <div v-if="assignIds.length > 0" class="transfer-assign-shortcut d-flex align-center">
                <v-btn prepend-icon="mdi-close" color="error" @click="resetAssignIds()" class="mr-3">
                    Unselect
                </v-btn>
                <v-btn prepend-icon="mdi-check-circle-outline" class="button-1" @click="openAssignDetailDialog">
                    Assign Transfer
                </v-btn>
            </div>

            <v-dialog v-model="returnTrip" max-width="400" persistent>
                <v-card prepend-icon="mdi-map-marker"
                    :text="returnTripQueue.length > 0 ? returnTripQueue.length + ' return pick-ups detected. Do you want to select?' : 'Return pick-up detected. Do you want to select?'"
                    title="Return Pick Up?">
                    <template v-slot:actions>
                        <v-spacer></v-spacer>

                        <v-btn @click="this.returnTrip = false">
                            No
                        </v-btn>

                        <v-btn @click="returnTripQueue.length > 0 ? processReturnTrips() : assignReturnTrip()">
                            Yes
                        </v-btn>
                    </template>
                </v-card>
            </v-dialog>

            <div class="pdf-container" v-if="data && data.data.priority === null">
                <iframe :src="pdfData" frameborder="0" v-if="pdfData"></iframe>
                <div v-else>
                    Loading PDF...
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import DriverAssignmentCard from '@/components/AssignmentCard.vue';
import { toast } from 'vue3-toastify';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { httpGet, httpPost } from '@/services/http';
import { indexedDBService } from '@/services/indexedDBService';
import { parsePhoneNumber } from 'libphonenumber-js';
import { debounce } from "vue-debounce";

export default {
    name: 'JobDetailView',
    components: {
        TopNav,
        Image,
        DriverAssignmentCard
    },
    data() {
        return {
            // Page Variables
            id: this.$route.params.id,
            isLoading: false,
            // Assign DTC Variables
            assignIds: [],
            isShiftKeyPressed: false,
            returnTripQueue: [],
            returnTrip: false,
            dntData: [],
            dntId: '',
            isAssignDetailDialogOpen: false,
            currentRole: null,
            // Data Variables
            data: null,
            groupedTransfers: [],
            flattenedTransfers: [],
            pdfData: null,
            subscription: null,
            // Stay Detail Add Variables
            isStayAddDialogOpen: false,
            newStay: {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            },
            // Stay Detail Edit Variables
            editStayDetailModal: {
                index: null,
                show: false,
            },
            // Guest Detail Edit Variables
            isPaxEditDialogOpen: false,
            editedPax: {
                no_of_adults: 0,
                no_of_childs: 0,
                no_of_infants: 0
            },
            // Itinerary Add Variables
            addItineraryShow: false,
            itineraries: [],
            types: [
                {
                    type: "Private",
                    slug: "pte",
                },
                {
                    type: "SIC",
                    slug: "sic",
                },
            ],
            categories: [],
            orgCategories: [],
            newItinerary: {
                type: "pte",
                category: "ARR",
                date: "",
                pickUpTime: "",
                pickUpLocation: null,
                pickUpLocationSuggestions: [],
                destinationLocation: null,
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: null,
            },
            // Transfer Edit Variables
            editItinerary: {
                type: "",
                category: "",
                date: "",
                pickUpTime: "",
                pickUpLocation: null,
                pickUpLocationSuggestions: [],
                destinationLocation: null,
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: null,
            },
            editTransferModal: {
                index: null,
                show: false,
            },
            // List Data Table
            headers: [
                { title: 'Time', key: 'pick_up_time', sortable: false },
                { title: 'Transfer Type', key: 'transfer_type', sortable: false },
                { title: 'Slug', key: 'transfer_type_special.transfer_type_special_slug', sortable: false },
                { title: 'From', key: 'from.main_text', sortable: false },
                { title: 'To', key: 'to.main_text', sortable: false },
                { title: 'Remarks', key: 'remarks', sortable: false },
                { title: 'Driver', key: 'driver', sortable: false },
                { title: 'Contractor', key: 'contractor', sortable: false },
                { title: 'Tour Guide', key: 'tour_guide', sortable: false },
                { title: 'Actions', key: 'actions', sortable: false },
            ],
            // Loadings
            locationLoading: {
                stay: false,
                pick_up: false,
                destination: false,
            },
            // Validations
            stayValidation: {
                startDate: [
                    (v) => !!v || "Start date is required",
                    (v) => {
                        if (!v) return true;
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        return (
                            new Date(v).setHours(0, 0, 0, 0) >= today ||
                            "Start date must be today or a future date"
                        );
                    },
                    (v) => {
                        if (!this.newStay.endDate || !v) return true;
                        return (
                            new Date(v) <= new Date(this.newStay.endDate) ||
                            "Start date must be before or the same as end date"
                        );
                    },
                ],
                endDate: [
                    (v) => !!v || "End date is required",
                    (v) => {
                        if (!this.newStay.startDate || !v) return true;
                        return (
                            new Date(v) >= new Date(this.newStay.startDate) ||
                            "Start date must be before or the same as end date"
                        );
                    },
                ],
                locationRules: [(v) => !!v || "Location is required"],
            },
            itineraryLocationValidation: {
                pickUpLocationRules: [(v) => !!v || "Pick up location is required"],
                destinationLocationRules: [(v) => !!v || "Destination location is required"],
            },
        }
    },
    watch: {
        "newStay.startDate": function (newVal, oldVal) {
            // When the start date is set and the end date is empty, autofill the end date
            if (newVal && !this.newStay.endDate) {
                this.newStay.endDate = newVal; // Set the end date to the same as start date
            }
        },
        "newStay.endDate": function (newVal) {
            if (this.editStayDetailModal.show && this.$refs.editStayForm) {
                this.$refs.editStayForm.validate();
            } else if (!this.editStayDetailModal.show && this.$refs.addStayForm) {
                this.$refs.addStayForm.validate();
            }
        },
        "newItinerary.type": function (newVal, oldVal) {
            // Your logic here
            console.log('Itinerary type changed from', oldVal, 'to', newVal);
            // Add your custom logic to handle the type change
            this.filterTransferTypes()

            // Check if the selected category is in the new list
            const foundCategory = this.categories.find((a) => a.slug === this.newItinerary.category);

            if (!foundCategory) {
                // If not found, select the first element in the list
                this.newItinerary.category = this.categories.length > 0 ? this.categories[0].slug : null;
            }
        },
    },
    created() {
        this.init();
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    },
    beforeUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    },
    computed: {
        flattenedTransfers() {
            return this.groupedTransfers.flatMap((group, groupIndex) =>
                group.transfers.map((transfer, transferIndex) => ({
                    ...transfer,
                    index: `${groupIndex + 1}.${transferIndex + 1}`
                }))
            );
        }
    },
    methods: {
        // Page Functions
        async init() {
            await Promise.all([
                indexedDBService.getDtcsData(),
                indexedDBService.getJobsData(),
                indexedDBService.getAssignedJobRequestsData(),
                indexedDBService.getTransferTypesData()
            ]);

            // Now that IndexedDB data is loaded, we can fetch the job details and other data
            this.fetchJobDetails();
            this.fetchAndDisplayPDF();
            this.fetchDtcData();
            this.fetchTransferTypesData();
        },
        fetchJobDetails() {
            const observableData = liveQuery(async () => {
                const job = await db.jobs.get(this.id);
                if (!job) return null;

                const tourist = await db.users.get(job.tourist_uuid);
                const transfers = await db.transfers.where('job_uuid').equals(job.uuid).toArray();
                const stays = await db.stays.where('job_uuid').equals(job.uuid).toArray();

                // Fetch hotel locations for stays
                const staysWithHotelDetails = await Promise.all(stays.map(async stay => {
                    const hotelLocation = await db.locations.get(stay.hotel_uuid);
                    return {
                        ...stay,
                        hotel: hotelLocation
                    };
                }));

                // Fetch related data for transfers
                const transfersWithDetails = await Promise.all(transfers.map(async transfer => {
                    const from = await db.locations.get(transfer.from_uuid);
                    const to = await db.locations.get(transfer.to_uuid);
                    const transferType = await db.transfer_types.get(transfer.transfer_type_special_uuid);
                    const assignedJobRequestDetails = await db.assigned_job_request_details
                        .where('transfer_uuid').equals(transfer.uuid).toArray();

                    const assignedJobRequests = await Promise.all(assignedJobRequestDetails.map(async detail => {
                        const request = await db.assigned_job_requests.get(detail.assigned_job_request_uuid);
                        const user = request ? await db.users.get(request.user_uuid) : null;
                        return {
                            ...detail,
                            assigned_job_request: {
                                ...request,
                                user
                            }
                        };
                    }));

                    return {
                        ...transfer,
                        from,
                        to,
                        transfer_type: transferType,
                        assigned_job_request_details: assignedJobRequests
                    };
                }));

                return {
                    data: {
                        ...job,
                        tourist,
                        transfers: transfersWithDetails,
                        stays: staysWithHotelDetails
                    }
                };
            });

            this.subscription = observableData.subscribe(result => {
                this.data = result;
            });
        },
        groupTransfers() {
            if (this.data && this.data.data && this.data.data.transfers) {
                const transfers = this.data.data.transfers;
                const groups = {};
                transfers.forEach(transfer => {
                    const date = this.formatDate(transfer.pick_up_time);
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(transfer);
                });
                Object.keys(groups).forEach(date => {
                    groups[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
                });
                const sortedGroups = Object.keys(groups).sort((a, b) => new Date(a) - new Date(b));
                this.groupedTransfers = sortedGroups.map(date => ({
                    date,
                    transfers: groups[date]
                }));
            } else {
                this.groupedTransfers = [];
            }
        },
        async fetchAndDisplayPDF() {
            const config = {
                responseType: 'blob', // Important for PDFs
            }

            await httpGet('/api/v1/travel_agents/jobs/pdf/' + this.id, config)
                .then(response => {
                    // Convert the Blob into a Data URL
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        this.pdfData = reader.result; // This will be a Data URL
                        // Now you can pass this.pdfData to VuePDF as the src
                        console.log('data ready', this.pdfData)
                    };
                    reader.readAsDataURL(response.data); // Read the blob as Data URL
                    console.log('/api/v1/travel_agents/jobs/pdf/' + this.id, this.pdfData)
                }).catch(error => {
                    console.error(error)
                })
        },
        // Assign DTC Functions
        async fetchDtcData() {
            const observableData = liveQuery(async () => {
                const dtcRequests = await db.dtc_requests.orderBy('created_at').reverse().toArray();
                const users = await db.users.toArray();
                const dtcRequestDetails = await db.dtc_request_details.toArray();

                const userMap = new Map(users.map(user => [user.uuid, user]));

                return dtcRequests.map(request => {
                    const requester = userMap.get(request.requester_uuid) || null;
                    const user = userMap.get(request.user_uuid) || null;
                    const details = dtcRequestDetails.filter(detail => detail.dtc_request_uuid === request.uuid);

                    return {
                        ...request,
                        requester,
                        user,
                        details,
                    };
                });
            });

            this.subscription = observableData.subscribe(result => {
                this.dtcData = result
                    .filter(a => a.status === 'Accepted' && !a.deleted_at)
                    .map(a => ({
                        ...a,
                        full_name: `${a.user.first_name} ${a.user.last_name} (${a.user.role.role_name})`
                    }));
                this.filterDTCData();
            });
        },
        filterDTCData() {
            if (this.currentRole) {
                this.dntData = this.dtcData.filter(a => a.user.role.role_slug === this.currentRole);
            } else {
                this.dntData = [...this.dtcData];
            }
        },
        assignReturnTrip() {
            if (this.returnTrip) {
                this.assignIds.push(this.returnTrip);
                this.returnTrip = false;
            }
        },
        openAssignDetailDialog() {
            this.isAssignDetailDialogOpen = true;
        },
        closeAssignDetailDialog() {
            this.isAssignDetailDialogOpen = false;
        },
        handleKeyDown(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = true;
            }
        },
        handleKeyUp(event) {
            if (event.key === 'Shift') {
                this.isShiftKeyPressed = false;
                const unassignedReturnTrips = this.returnTripQueue.filter(trip => !this.assignIds.includes(trip.uuid));
                if (unassignedReturnTrips.length > 0) {
                    this.returnTrip = true; // Show dialog
                } else {
                    this.returnTripQueue = [];
                }
            }
        },
        processReturnTrips() {
            if (this.returnTripQueue.length > 0) {
                this.returnTripQueue.forEach(trip => {
                    this.assignIds.push(trip.uuid);
                });
                this.returnTripQueue = [];
            }
            this.returnTrip = false;
        },
        resetAssignIds() {
            this.assignIds = [];
            this.currentRole = null;
            this.filterDTCData();
        },
        handleCheckboxClick(transferId, role) {
            const index = this.assignIds.indexOf(transferId);
            if (index === -1) {
                this.assignIds.push(transferId);
                this.currentRole = role;
                this.filterDTCData();

                let selectedTransfer = this.flattenedTransfers.find((a) => a.uuid === transferId);
                if (selectedTransfer) {
                    let returnTrip = this.flattenedTransfers.find((a) =>
                        a.to && a.from && selectedTransfer.from && selectedTransfer.to && a.tourist &&
                        a.to.uuid === selectedTransfer.from.uuid &&
                        a.from.uuid === selectedTransfer.to.uuid &&
                        a.tourist.uuid === selectedTransfer.tourist.uuid &&
                        this.isSameDate(a.pick_up_time, selectedTransfer.pick_up_time)
                    );

                    if (returnTrip && !this.assignIds.includes(returnTrip.uuid)) {
                        if (this.isShiftKeyPressed) {
                            this.returnTripQueue.push(returnTrip);
                        } else {
                            this.returnTrip = returnTrip.uuid;
                        }
                    }
                }
            } else {
                this.assignIds.splice(index, 1);
                if (this.assignIds.length === 0) {
                    this.currentRole = null;
                    this.filterDTCData();
                }
            }
        },
        async submitRequest() {
            const data = { id: this.dntId, transfer_ids: this.assignIds };
            const id = toast.loading('Please wait...');
            this.isLoading = true;
            try {
                await httpPost('/api/v1/travel_agents/jobs/assigns/add', data);
                this.dntId = null;
                this.currentRole = null;
                this.assignIds = [];
                toast.update(id, {
                    render: "Request submitted successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000
                });
                this.isLoading = false;
                this.closeAssignDetailDialog();
                this.fetchJobDetails();
            } catch (error) {
                console.error(error);
                toast.update(id, {
                    render: "An error occurred",
                    type: "error",
                    isLoading: false,
                    autoClose: 3000
                });
                this.isLoading = false;
            }
        },
        // Stay Detail Add Functions
        openStayAddDialog() {
            this.isStayAddDialogOpen = true;
        },
        closeStayAddDialog() {
            this.isStayAddDialogOpen = false;
            this.newStay = {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            };
        },
        async addStayDetail() {
            const isValid = await this.$refs.addStayForm.validate();

            if (isValid.valid) {
                this.newStay = {
                    startDate: "",
                    endDate: "",
                    location: "",
                    locationSuggestions: [],
                };
                this.$refs.addStayForm.reset();
                this.$refs.addStayForm.resetValidation();
            }
        },
        // Stay Detail Edit Functionss
        openStayEditDialog(index) {
            this.editStayDetailModal.show = true;
            this.editStayDetailModal.index = index;
            let stay = this.data.data.stays[index];
            if (stay) {
                this.newStay = {
                    startDate: stay.start_date,
                    endDate: stay.end_date,
                    location: stay.hotel_uuid,
                    locationSuggestions: [
                        stay.hotel
                    ],
                };
                console.log(stay)
                console.log(this.newStay)
            }
        },
        closeStayEditDialog() {
            this.newStay = {
                startDate: "",
                endDate: "",
                location: "",
                locationSuggestions: [],
            };
            this.$refs.editStayForm.reset();
            this.$refs.editStayForm.resetValidation();
            this.editStayDetailModal.show = false;
        },

        async saveStayEdit() {
            this.closeStayEditDialog();
        },
        // Guest Detail Edit Functions
        openPaxEditDialog() {
            this.editedPax = {
                no_of_adults: this.data.data.no_of_adults,
                no_of_childs: this.data.data.no_of_childs,
                no_of_infants: this.data.data.no_of_infants
            };
            this.isPaxEditDialogOpen = true;
        },

        closePaxEditDialog() {
            this.isPaxEditDialogOpen = false;
        },
        async savePaxEdit() {
            Object.assign(this.data.data, this.editedPax);
            this.closePaxEditDialog();
        },
        // General Functions
        formatDate(date) {
            const d = new Date(date);
            const day = d.getDate().toString().padStart(2, "0");
            const month = (d.getMonth() + 1).toString().padStart(2, "0");
            const year = d.getFullYear();

            return `${day}/${month}/${year}`;
        },
        formatTime(dateTimeString) {
            const date = new Date(dateTimeString);
            const options = { hour: 'numeric', minute: 'numeric', hour12: false };
            return date.toLocaleTimeString('en-US', options);
        },
        fetchLocationSuggestions(value, field, editItinerary) {
            if (!value) {
                this.locationSuggestions = [];
                return;
            }

            httpGet(`/api/v1/travel_agents/locations/search?value=${encodeURIComponent(value)}`)
                .then((result) => {
                    console.log("Is Editing Itinerary ", editItinerary);
                    console.log("Current Searching for ", value);
                    if (result.status && result.data) {
                        if (field === "stay") {
                            this.newStay.locationSuggestions = result.data.data;
                        } else if (field === "pick_up") {
                            if (!editItinerary) {
                                this.newItinerary.pickUpLocationSuggestions = result.data.data;
                                if (this.newItinerary.category === "ARR") {
                                    let location = this.categories.find(
                                        (a) => a.slug === this.newItinerary.category
                                    ).location;
                                    this.newItinerary.pickUpLocationSuggestions.unshift(location);
                                }
                            } else {
                                this.editItinerary.pickUpLocationSuggestions = result.data.data;
                                if (this.editItinerary.category === "ARR") {
                                    let location = this.categories.find(
                                        (a) => a.slug === this.editItinerary.category
                                    ).location;
                                    this.editItinerary.pickUpLocationSuggestions.unshift(location);
                                }
                            }
                            console.log(this.newItinerary.pickUpLocation);
                        } else if (field === "destination") {
                            console.log(result.data.data)
                            console.log('Current edit mode: ' + editItinerary)
                            if (!editItinerary) {
                                this.newItinerary.destinationLocationSuggestions = result.data.data;
                                let location = this.categories.find(
                                    (a) => a.slug === this.newItinerary.category
                                ).location;
                                if (this.newItinerary.category !== "ARR" && location) {
                                    this.newItinerary.destinationLocationSuggestions.unshift(location);
                                }
                            } else {
                                this.editItinerary.destinationLocationSuggestions = result.data.data;
                                let location = this.categories.find(
                                    (a) => a.slug === this.editItinerary.category
                                ).location;
                                if (this.editItinerary.category !== "ARR" && location) {
                                    this.editItinerary.destinationLocationSuggestions.unshift(location);
                                }
                            }
                        }
                        this.locationLoading[field] = false;
                    } else {
                        throw new Error("Failed to fetch location suggestions");
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    this.locationSuggestions = [];
                    this.locationLoading[field] = false;
                });
        },
        debouncedFetchLocationSuggestions: debounce(function (
            value,
            field,
            editItinerary = false
        ) {
            this.locationLoading[field] = true;
            this.fetchLocationSuggestions(value, field, editItinerary);
        },
            500),
        // Itinerary Add Functions
        fetchTransferTypesData() {
            const observableData =
                liveQuery(async () => {
                    // Fetch transfer types with their related locations and location details
                    const transfers = await db.transfer_types.orderBy('transfer_type_special_name').toArray();
                    const locations = await db.locations.toArray();
                    const locationDetails = await db.location_details.toArray();

                    // Create a map for locations and location details for easy lookup
                    const locationMap = new Map(locations.map(location => [location.uuid, location]));
                    const locationDetailMap = new Map(locationDetails.map(detail => [detail.uuid, detail]));

                    // Attach related location and location details to each transfer
                    const transfersWithDetails = transfers.map(transfer => {
                        const location = locationMap.get(transfer.location_uuid) || null;
                        if (location) {
                            location.detail = locationDetailMap.get(location.detail_uuid) || null;
                        }
                        return {
                            type: transfer.transfer_type_special_name,
                            slug: transfer.transfer_type_special_slug,
                            location: location ? location : null,
                            start_time: transfer.start_time,
                            return_time: transfer.return_time,
                            sic_start_time: transfer.sic_start_time,
                            sic_return_time: transfer.sic_return_time,
                            private: transfer.private,
                            sic_per_head: transfer.sic_per_head,
                            sic_per_hotel: transfer.sic_per_hotel,
                            private_tour_guide: transfer.private_tour_guide,
                            sic_tour_guide: transfer.sic_tour_guide,
                        };
                    });

                    return transfersWithDetails;
                });

            this.subscription = observableData.subscribe((result) => {
                this.orgCategories = result
                this.filterTransferTypes()
            });
        },
        filterTransferTypes() {
            if (!(this.editTransferModal.show === true)) {
                if (this.newItinerary.type === 'pte') {
                    this.categories = this.orgCategories.filter((a) => a.private === 1)
                } else {
                    this.categories = this.orgCategories.filter((a) => a.sic_per_head === 1)
                }
            } else {
                if (this.editItinerary.type === 'pte') {
                    this.categories = this.orgCategories.filter((a) => a.private === 1)
                } else {
                    this.categories = this.orgCategories.filter((a) => a.sic_per_head === 1)
                }
            }
        },
        addItinerary(date) {
            this.addItineraryShow = !this.addItineraryShow;
            const year = date.getFullYear();
            const month = `0${date.getMonth() + 1}`.slice(-2); // Months are 0-based
            const day = `0${date.getDate()}`.slice(-2);
            const formattedDate = `${year}-${month}-${day}`;
            const defaultTime = "09:00"; // Default time set to 9:00 AM
            const returnTime = "21:00"; // Return time set to 9:00 PM

            this.newItinerary = {
                type: "pte",
                category: "ARR",
                date: formattedDate, // Assuming you want to set the date here as well
                pickUpTime: defaultTime,
                pickUpLocation: "",
                pickUpLocationSuggestions: [],
                destinationLocation: "",
                destinationLocationSuggestions: [],
                tickets: [],
                remarks: "",
                returnTime: returnTime,
            };

            const matchingStay = this.data.data.stays.find((stay) => {
                const stayStart = new Date(stay.start_date).getTime();
                const stayEnd = new Date(stay.end_date).getTime();
                const itineraryDateTime = date.getTime();
                return itineraryDateTime >= stayStart && itineraryDateTime <= stayEnd;
            });

            if (this.getItinerariesForDate(date).length == 0) {
                if (matchingStay) {
                    console.log(matchingStay);
                    // Set the pick-up location to the stay's location if a matching stay is found
                    this.newItinerary.pickUpLocation = matchingStay.location;
                    // Assuming `locationSuggestions` might need updating too
                    this.newItinerary.pickUpLocationSuggestions = matchingStay.locationSuggestions;
                }
            } else {
                const lastItinerary = this.getItinerariesForDate(date)[
                    this.getItinerariesForDate(date).length - 1
                ];

                // Default type & category
                this.newItinerary.type = lastItinerary.transfer_type_slug;
                this.newItinerary.category = lastItinerary.transfer_type.transfer_type_special_slug;

                lastItinerary.to['uuid'] = lastItinerary.to.detail_uuid

                // Default Pick Up Location
                this.newItinerary.pickUpLocation = lastItinerary.to.detail_uuid;
                this.newItinerary.pickUpLocationSuggestions =
                    [lastItinerary.to];

                // Add an hour to the pick-up time of the last itinerary
                const lastPickupTime = this.$moment(lastItinerary.pick_up_time);
                const newPickupTime = lastPickupTime.add(1, 'hour');

                this.newItinerary.pickUpTime = newPickupTime.format('HH:mm');
            }
        },
        getItinerariesForDate(date) {
            if (!this.data || !this.data.data || !this.data.data.transfers) {
                return [];
            }

            const formattedDate = this.$moment(date).format('YYYY-MM-DD');

            return this.data.data.transfers.filter((transfer) => {
                const transferDate = this.$moment(transfer.pick_up_time).format('YYYY-MM-DD');
                return transferDate === formattedDate;
            });
        },
        addTickets(event, isEdit = false) {
            const files = event.target.files; // Get selected files from input event
            const itinerary = isEdit ? this.editItinerary : this.newItinerary;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // Check if file is not already selected
                if (!itinerary.tickets.find((selectedFile) => selectedFile.name === file.name)) {
                    itinerary.tickets.push(file); // Add file to the appropriate tickets array
                }
            }
        },
        onCategoryChange(newValue, isEdit = false) {
            console.log("Category changed to:", newValue);

            if (!newValue) {
                // If newValue is null or undefined, reset the relevant fields
                if (isEdit) {
                    this.editItinerary.pickUpLocation = null;
                    this.editItinerary.pickUpLocationSuggestions = [];
                    this.editItinerary.destinationLocation = null;
                    this.editItinerary.destinationLocationSuggestions = [];
                } else {
                    this.newItinerary.pickUpLocation = null;
                    this.newItinerary.pickUpLocationSuggestions = [];
                    this.newItinerary.destinationLocation = null;
                    this.newItinerary.destinationLocationSuggestions = [];
                }
                return;
            }

            let category = this.categories.find((a) => a.slug === newValue);
            let location = category.location;
            if (location) {
                if (newValue === "ARR") {
                    if (isEdit) {
                        this.editItinerary.pickUpLocation = location.uuid;
                        this.editItinerary.pickUpLocationSuggestions = [location];
                    } else {
                        this.newItinerary.pickUpLocation = location.uuid;
                        this.newItinerary.pickUpLocationSuggestions = [location];
                    }
                } else {
                    if (isEdit) {
                        this.editItinerary.destinationLocation = location.uuid;
                        this.editItinerary.destinationLocationSuggestions = [location];
                    } else {
                        this.newItinerary.destinationLocation = location.uuid;
                        this.newItinerary.destinationLocationSuggestions = [location];
                    }
                }
            }

            if (category.start_time !== null || category.return_time !== null || category.sic_start_time !== null || category.sic_return_time !== null) {
                const getFormattedTime = (date, timeString) => {
                    if (!timeString) {
                        return null;
                    }
                    const [hours, minutes] = timeString.split(":").map(Number);
                    const currentDate = new Date(date);
                    currentDate.setHours(hours, minutes, 0, 0); // Ensure seconds and milliseconds are set to 0
                    return `${`0${currentDate.getHours()}`.slice(-2)}:${`0${currentDate.getMinutes()}`.slice(-2)}`;
                };

                if (isEdit) {
                    if (this.editItinerary.type === "pte") {
                        if (category && category.start_time) {
                            this.editItinerary.pickUpTime = getFormattedTime(this.editItinerary.date, category.start_time);
                        }
                    } else {
                        if (category && category.sic_start_time) {
                            this.editItinerary.pickUpTime = getFormattedTime(this.editItinerary.date, category.sic_start_time);
                        }
                    }
                } else {
                    if (this.newItinerary.type === "pte") {
                        if (category && category.start_time) {
                            console.log('Got time start')
                            this.newItinerary.pickUpTime = getFormattedTime(this.newItinerary.date, category.start_time);
                        }
                        if (category && category.return_time) {
                            console.log('Got return start')
                            this.newItinerary.returnTime = getFormattedTime(this.newItinerary.date, category.return_time);
                        }
                    } else {
                        if (category && category.sic_start_time) {
                            this.newItinerary.pickUpTime = getFormattedTime(this.newItinerary.date, category.sic_start_time);
                        }
                        if (category && category.sic_return_time) {
                            this.newItinerary.returnTime = getFormattedTime(this.newItinerary.date, category.sic_return_time);
                        }
                    }
                }
            }
        },
        sendWhatsapp(number) {
            const formattedNumber = number.toString().replace(/[\s-()+]/g, '');
            window.open(`https://api.whatsapp.com/send?phone=${formattedNumber}`, '_blank').focus();
        },
        isSameDate(date1, date2) {
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            return (
                d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() &&
                d1.getDate() === d2.getDate()
            );
        },
        formatDateFull(date) {
            const months = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const d = new Date(date);
            const day = d.getDate();
            const month = months[d.getMonth()];
            const year = d.getFullYear();
            return `${day} ${month} ${year}`;
        },
        formatPhoneNumber(contactNumber) {
            if (!contactNumber) return '';

            try {
                const phoneNumber = parsePhoneNumber(
                    contactNumber.contact_number,
                    contactNumber.country_code.country_code
                );
                return phoneNumber.formatInternational();
            } catch (error) {
                console.error('Error parsing phone number:', error);
                return `+${contactNumber.country_code.country_code} ${contactNumber.contact_number}`;
            }
        },
    },
    computed: {
        groupedTransfers() {
            if (this.data && this.data.data && this.data.data.transfers) {
                const transfers = this.data.data.transfers;
                const groups = {};
                transfers.forEach(transfer => {
                    const date = this.$moment(transfer.pick_up_time).format('DD MMM yyyy');
                    if (date !== "Invalid Date") {
                        if (!groups[date]) {
                            groups[date] = [];
                        }
                        groups[date].push(transfer);
                    } else {
                        console.warn(`Invalid date for transfer:`, transfer);
                    }
                });
                Object.keys(groups).forEach(date => {
                    groups[date].sort((a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time));
                });
                const sortedGroups = Object.keys(groups).sort((a, b) => new Date(a) - new Date(b));
                return sortedGroups.map(date => ({
                    date,
                    transfers: groups[date]
                }));
            } else {
                return [];
            }
        }
    }
}
</script>

<style src="@/assets/css/index.css" scoped></style>
<style src="@/assets/css/job.css" scoped></style>
<style scoped>
.job-infomation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.job-infomation .button-1 {
    margin-top: unset !important;
}

.user .image-container {
    margin-right: 20px;
}

.priority-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.timer {
    margin-top: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.timer h3 {
    font-size: 2.5em;
}

.list {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin: 0;
    flex: 1;
}

.list-item {
    cursor: pointer;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.list-item:hover {
    background-color: var(--secondary-dark-grey);
}

.list-item-subtitle {
    font-size: 0.9em;
    color: #666;
}

@media screen and (max-width: 850px) {
    .priority-details {
        grid-template-columns: repeat(1, 1fr);
    }

    .list-item {
        text-align: center;
    }
}
</style>
